.markdown-body ul,
ol {
  font-family: "Noto Sans TC", "Inter", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", serif;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  margin: 1.5em 0;
  padding: inherit;
}

.markdown-body img {
  max-width: 100%;
}
