.container {
  background-color: white !important;
  box-shadow: none !important;
}
.container tr {
  display: flex;
}

.container th {
  border-bottom: 0px;
}

.container a {
  text-decoration: none;
  margin: auto;
  color: black;
}
