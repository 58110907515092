.container {
  background: #d9dce2;
  padding: 8px 24px;
  border-top: 2px solid #434f6d33;
}
.container tr {
  display: flex;
}

.container th {
  border-bottom: 0px;
}

.container a {
  text-decoration: none;
  margin: auto;
}
