.main {
  padding-top: 6rem;
  margin-top: -6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 96px - 6rem);
}

.layout {
  background: url("assets/background1.jpg");
  background-size: cover;
}
.main > div:nth-child(even) {
  padding: 2rem;
  margin: 2rem;
}

.main > div:nth-child(2) > div {
  border: 2px solid #fda802;
}
.main > div:nth-child(even) > div {
  border-radius: 3rem;
  background: rgba(256, 256, 256, 0.6);
}

.main > div {
  width: 100%;
  padding: 0 2rem;
}

.img img[alt="banner"] {
  position: relative;
  right: -2rem;
  width: 100%;
}

@media screen and (max-width: 899px) {
  .img {
    margin-left: -1rem !important;
  }
  .img img,
  .img img[alt="banner"] {
    width: calc(100% + 2rem);
    height: auto;
  }

  .img img[alt="banner"] {
    position: relative;
    right: 0;
  }
  .main > div:nth-child(even) {
    padding: 2rem;
    margin: 2rem;
  }
  .main > div {
    width: 100%;
    padding: 0 1rem;
  }
  .main > div:nth-child(even) > div {
    padding: 2rem;
  }
}
